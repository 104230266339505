import { createApp } from 'vue'
import App from './App.vue'
import routes from './router'
import axios from './plugins/axios'
import store from './store/index'
import i18n from '@/i18n/index.js'
import antD from 'ant-design-vue';
// import './mock/mock'
import 'ant-design-vue/dist/reset.css';
import '@/styles/index.scss'
// import '@/assets/fonts/font.css'
import vue3GoogleLogin from 'vue3-google-login'
import moment from 'moment'
import { notification } from 'ant-design-vue';
import {numberToCurrencyNo} from '@/plugins/numberToCurrencyNo.js'
import {newNumber} from "@/plugins/NewNumber";
import "flag-icon-css/css/flag-icons.css"
import VueDragscroll from "vue-dragscroll";
import plugins from '@/plugins/plugins';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';



notification.config({
    placement: 'top',
    top: '100px',
    duration: null,
});

const app = createApp(App);
app.config.globalProperties.$axios=axios
app.config.globalProperties.$notification=notification
// app.config.globalProperties.$bridge = VueJsBridge
app.directive('cuNumber', {
    mounted: function (el, binding) {
       el.innerHTML = numberToCurrencyNo(binding.value)
    }
})
app.directive('cuPrice',(el,binding)=>{
    el.innerHTML = numberToCurrencyNo(binding.value,'price')
})
app.directive('cuPriceNoZero',(el,binding)=>{
    el.innerHTML = numberToCurrencyNo(binding.value,'price','','USD')
})
app.directive('cuPriceAed',(el,binding)=>{
    el.innerHTML = numberToCurrencyNo(binding.value,'price','00','AED')
})
app.directive('newNumber', (el,binding)=>{
    el.innerHTML = newNumber(binding.value.price,binding.value.currency)
})

console.log(plugins.getLocal('lang'))

if (plugins.getLocal('lang') === 'en') {
    import('@/assets/fonts/font.css').then(() => { 
        console.log('en')
    })
  } else if (plugins.getLocal('lang') === 'ar') {
    import('@/assets/fonts/arFont.css').then(() => {
      console.log('ar')
    });
  }


app.config.warnHandler = () => {};
app.use(routes)
    .use(antD)
    .use(store)
    .use(i18n)
    .use(moment)
    .use(VueDragscroll)
    .use(ElementPlus)
    .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
    })//google登录token
    .mount('#app');

