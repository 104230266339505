/*
 * @Author: zouzirui
 * @Date: 2024-07-25 15:48:47
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-08-01 13:59:53
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\DetailsAndetc.js
 * @Description:
 * ar中带英文说明没有给对应的文案
 */

const ar = {
	JeansText: "الأناقة الخالدة لحقيبة هيرميس كيلي",
	JeansDate: "اكتشفوا المزيد",
	shoesText: "كيف تعيد ملابس الشارع‘ تعريفَ 'الفخامة الجديدة'",
	REFERENCE:'الرقم الرجعي',
	shoesDate:'اكتشفوا المزيد',
	Trompefeathers: 'أزرق دخاني فائق الأناقة',
	BRAND:'العلامات التجارية',
	GENDER:'الجنس',
	ATHLETE:"",
	DIAL:'المينا',
	PRODUCT:'المنتَج',
	BRACELETMATERIAL:'خامة السوار',
	PRODUCTTYPE:'تفاصيل المنتج',
	EXTERIALMATERIAL:'الخامة الخارجية',
	HARDWARE:'الإكسسوارات المعدنية',
	SizeText:'المقاس (الطول*العرض*الارتفاع)',
	COUNTRYOFORIGIN:'بلد المنشأ',
	YEAROFPRODUCTION:'سنة الإنتاج',
	COLOUR:"اللون",
	OTHERINCLUSIONS:'مرفقات أخرى',
	MECHANISM:'الآلية',
	CASEMATERIAL:'خامة العلبة',
	BRACELETCOLOUR:"لون السوار",
	SPECIALFEATURES:"الخصائص المميزة",
	SPORT:'الرياضة',
	SIGNED:'موقَّعة',
	TEAMText:'فريق',
	SIZE:'نوع المنتَج',
	MATERIALS:'الخامات',
	MODELYEAR:'سنة الموديل',
	GEMSTONE:'الأحجار الكريمة',
	MATERIALText:'الخامة',
	CARATWEIGHT:'الوزن بالقيراط',
	YEARText:'السنة',
	GRADE:"التقييم",
	SCORE:"الدرجة",
	YEARSET:'العام والمجموعة',
	MAKE:'العلامة التجارية',
	CYLINDER:"عدد الأسطوانات",
	MILEAGE:'المسافة المقطوعة',
	ROOKIECARDS:"بطاقات المبتدئين",
	PRISTINE:'بحالتها الإصلية',
	CONDITIONPARAMETERS:'معايير تحديد الحالة',
	TYPEText:'الخامة',
	EXTERIORCOLOUR:'اللون الخارجي',
	TaxesIncluded:'الضرائب المدرجة',
	GEMSTONEText:'قطع الأحجار الكريمة وشكلها',
	InternationalShippingAvailable:'خدمات الشحن الدولي متاحة',
	MODEL:"الموديل",
	ITEMText:"القطعة",
	ProductDetails:"تفاصيل المنتج",
	DISCOVERMORE:'تسوَّقوا المجموعة',
	AuthenticityGuaranteed:'الأصالة مضمونة',
	Collectibles:"المقتنيات",
	FineJewelry:"المجوهرات الثمينة",
	Handbags:"حقائب اليد",
	ShoesText:'الأحذية',
	accessoriestext:"ظهور اتجاه الموضة المحبوبة مسبقاً",
	SportsMerorabilia:'ميزوري سبورت',
	SportsCards:"بطاقات التداول الرياضية",
	ReadytoWear:'الملابس الجاهزة',
	AutomobilesText:'السيارات',
	accessoriesDate:"اكتشفوا المزيد",
	CASEDIAMETER:'مقاس العلبة (مم)',
	TRANSMISSION:'ناقل الحركة',
	Pristine:'بحالتها الإصلية',
	Excellent:'بحالة ممتازة ',
	GoodText:" بحالة جيدة ",
	FairText:"بحالة مقبولة",
	LeatherGoods:'الجلديات',
	leatherBagDate:'14 مايو، 2024',
	bagsDate:'اكتشفوا المزيد',
	CATEGORY:'الفئة',
	PristineDicDesc:'ما تزال القطعة الأصلية بحالة ممتازة تُظهر أناقتَها الخالدة والحِرفية الفائقة في تصنيعها.',
	readytoWearContent: 'تتميز هذه الساعة الفاخرة بمينا باللون الأزرق الدخاني الساحر الذي يبرز نمطَ "غراند تابيسيري" الأيقوني المعقَّد. المينا مزيَّنٌ بعلامات ساعات من الذهب الأبيض وعقارب رويال أوك، جميعُها تتألَّق بطبقة مضيئة لتعزيز الرؤية.',
};
const en = {
	Collectibles:"Collectibles",
	CATEGORY:'CATEGORY',
	FairText:"Fair",
	GoodText:"Good",
	leatherBagDate:"May 14th, 2024",
	Pristine:'Pristine',
	Excellent:"Excellent",
	TRANSMISSION:"TRANSMISSION",
	AutomobilesText:'Automobiles',
	accessoriesDate:"April 25th, 2024",
	bagsDate:'April 25th, 2024',
	SportsMerorabilia:'Sports Merorabilia',
	JeansText: 'The Timeless Elegance of the Hermès Kelly',
	ReadytoWear:"Ready to Wear",
	SportsCards:"Sports Cards",
	accessoriestext:"The Rolex Phenomenon Explained",
	BRAND:'BRAND',
	ShoesText:"Shoes",
	ITEMText:'ITEM',
	DIAL:'DIAL',
	FineJewelry:'Fine Jewelry',
	LeatherGoods:'Leather Goods',
	Handbags:"Hand bags",
	GENDER:'GENDER',
	TaxesIncluded:'Taxes Included',
	PRISTINE:'PRISTINE',
	InternationalShippingAvailable:'International Shipping Available',
	CYLINDER:"CYLINDER",
	AuthenticityGuaranteed:'Authenticity Guaranteed',
	CONDITIONPARAMETERS:'CONDITION PARAMETERS',
	EXTERIORCOLOUR:'EXTERIOR COLOUR',
	SIGNED:'SIGNED',
	MECHANISM:'MECHANISM',
	GRADE:"GRADE",
	ProductDetails:'Product Details',
	YEARSET:'YEAR & SET',
	SCORE:'SCORE',
	SPECIALFEATURES:"SPECIAL FEATURES",
	YEARText:'YEAR',
	GEMSTONE:'GEMSTONE',
	ROOKIECARDS:"ROOKIE CARDS",
	PRODUCT:'PRODUCT',
	OTHERINCLUSIONS:"OTHER INCLUSIONS",
	PRODUCTTYPE:'PRODUCT TYPE',
	TYPEText:'TYPE',
	SizeText:'Size (L*W*H)',
	REFERENCE: 'REFERENCE',
	JeansDate: "April 25th, 2024",
	COLOUR:'COLOUR',
	MILEAGE:'MILEAGE',
	SIZES:'SIZES',
	SPORT:'SPORT',
	MATERIALS:'MATERIALS',
	CARATWEIGHT:'CARAT WEIGHT',
	shoesText: 'How Streetwear Defines “A New Luxury”',
	shoesDate:'April 25th, 2024',
	EXTERIALMATERIAL:'EXTERIAL MATERIAL',
	COUNTRYOFORIGIN: 'COUNTRY OF ORIGIN',
	HARDWARE:'HARDWARE',
	TEAM:"TEAM",
	ATHLETE:'ATHLETE',
	MATERIALText:'MATERIAL',
	PristineDicDesc:'Items in pristine condition are brand new, unused, and free from any defects.They exhibit flawless quality and are in perfect, mint condition.',
	MAKE:"MAKE",
	Trompefeathers: 'Trompe l’oeil feathers',
	CASEMATERIAL:'CASE MATERIAL',
	CASEDIAMETER:'CASE DIAMETER (MM)',
	BRACELETCOLOUR:'BRACELET COLOUR',
	BRACELETMATERIAL:'BRACELET MATERIAL',
	YEAROFPRODUCTION:"YEAR OF PRODUCTION",
	MODELYEAR:'MODEL YEAR',
	MODEL:'MODEL',
	DISCOVERMORE:'DISCOVER MORE',
	GEMSTONEText:"GEMSTONE CUT AND SHAPE",
	readytoWearContent: 'Black velvet cape with Phoenix-wing shaped shoulders adorned with a 3D chrome trompe l’oeil feathers embroidery.',
};

export { ar, en };
