/*
 * @Author: yangpeng
 * @Date: 2024-08-16 14:28:52
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-08-20 13:32:49
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\sconcept.js
 * @Description:
 */
const ar = {
	essentials: 'إسينشالز',
	supreme: 'سوبريم',
	vlone: 'فلون',
	newIn: 'NEW IN',
	apparel: 'التي شيرتات',
	kicks: 'الأحذية الرياضية',
	travis: 'ترافيس سكوت',
	offWhits: 'أوف وايت',
	assc: 'أنتي سوشال سوشال كلوب',
};
const en = {
	essentials: 'ESSENTIALS',
	supreme: 'SUPREME',
	vlone: 'VLONE',
	newIn: 'NEW IN',
	apparel: 'APPAREL',
	kicks: 'KICKS',
	travis: 'TRAVIS SCOTT',
	offWhits: 'OFF WHITE',
	assc: 'ASSC',
};

export { ar, en };
