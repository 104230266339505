/*
 * @Author: yangpeng
 * @Date: 2024-07-25 15:48:47
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-08-13 21:43:39
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\landingpage.js
 * @Description:
 */
const ar = {
	royaloak: 'فالكونز',
	offshore: 'كوليكتف',
	exploreInfo:'مرِّر لأسفل لاستكشاف رؤيتنا',
	landingDes: 'الوجهةُ المثلى لجامعي المقتنيات.',
	landingDiscover: 'اكتشفوا المزيد',
	landingDiscoverWap: 'ادخلوا عالَم جمع المقتنيات',
	landingDiscoverSpotlight: 'تسوَّقوا الساعات',
	landingPremier: 'الوجهة المثلى للمزادات والبيع الخاص',
	landingPremier1: 'للمقتنيات الراقية',
	landingPremier2: 'اكتشفوا فالكونز',
	landingPremierWap: 'الوجهة المثلى للمزادات والبيع',
	landingPremierWap1: 'الخاص للمقتنيات الراقية',
	landingPremierWap2: '',
	landingvideo1: 'الساعات',
	landingvideo2: 'حقائب اليد',
	landingvideo3: 'المقتنيات',
	landingvideo4: 'ذي إس كونسيبت',
	landingSpotlight: 'تسليط الضوء على',
	landingCartier: 'أوديمار بيغيه',
	landingEpitomizing: 'تصميمٌ يتحدَّى التقاليد بأناقة.',
	landingShop: 'تسوَّقوا الساعات',
	landingCollection: 'ادخلوا عالَم جمع المقتنيات',
	landingEpitomizingWap: 'تصميمٌ يتحدَّى التقاليد بأناقة.',
	landingEpitomizingWap1: '',
	landingSpotlightDiscover: 'اكتشفوا فالكونز',
	landingHotDiscover: 'اكتشفوا المزيد',
	sell1: 'بيعوا على موقع',
	sell2: 'فالكونز',
	sell3: 'سيساعدكم فريقنا المحترف في تحقيق أفضل قيمة لقِطَعكم.',
	sell4:'',
	sell6: 'ابدأوا البيع معنا',
	sellWap1: 'سيساعدكم فريقنا المحترف في تحقيق أفضل قيمة لقِطَعكم.',
	sellWap2: 'أفضل قيمة للقطع الخاصة بكم.',
	sellWap3: '',
	sellWap4: '',
	CarDetail1: 'ضمانُنا للأصالة',
	CarDetail2:
		'التزامُنا بالأصالة لا يتزعزع، فقبل أن تصل أيُّ قطعة إلى يديك، تخضع للفحص الدقيق من قِبل فريقنا من الخبراء في التحقُّق من الأصالة، كلٌّ',
	CarDetail3:
		'منهم معتَمَدٌ في مجاله. إنَّهم يتحقَّقون بدقَّةٍ من أصالة كلِّ قطعة، ما يضمن أنَّ ما تتلقاهُ استثنائيٌ بجدارة.',
	CarDetail4: '',
	carDeatilWap1: 'ضمانُنا للأصالة',
	carDeatilWap2: '',
	carDeatilWap3: 'التزامُنا بالأصالة لا يتزعزع، فقبل أن تصل أيُّ قطعة إلى يديك، تخضع',
	carDeatilWap4: 'للفحص الدقيق من قِبل فريقنا من الخبراء في التحقُّق من الأصالة، كلٌّ',
	carDeatilWap5: 'منهم معتَمَدٌ في مجاله. إنَّهم يتحقَّقون بدقَّةٍ من أصالة كلِّ قطعة، ما',
	carDeatilWap6: 'يضمن أنَّ ما تتلقاهُ استثنائيٌ بجدارة.',
	carDeatilWap7: '',
	priceOnRequest: 'السعر عند الطلب',
	carModel: 'الموديل',
	carYear: 'سنة الموديل',
	carMileage: 'عدد الإسطوانات',
	carCylinder: 'عداد المسافات',
	landingAPPAREL:'الملابس',
	landingKICKS: 'الأحذية'
};
const en = {
	royaloak: 'Falcons',
	offshore: 'Collective',
	landingDes: 'A destination for every collector.',
	exploreInfo:'scroll to explore our vision',
	landingDiscover: 'DISCOVER MORE',
	landingDiscoverSpotlight: 'DISCOVER MORE',
	landingDiscoverWap: 'DISCOVER MORE',
	landingPremier: '“The premier destination for auctions and',
	landingPremier1: 'private sales of luxury collectibles.”',
	landingPremier2: 'DISCOVER FALCONS',
	landingPremierWap: '“The premier destination for',
	landingPremierWap1: 'auctions and private sale of',
	landingPremierWap2: 'luxury collectibles.”',
	landingvideo1: 'TIMEPIECES',
	landingvideo2: 'HANDBAGS',
	landingvideo3: 'COLLECTIBLES',
	landingvideo4: 'THE S CONCEPT',
	landingSpotlight: 'SPOTLIGHT ON',
	landingCartier: 'Audemars Piguet',
	landingEpitomizing: 'A design that gracefully challenges tradition.',
	landingShop: 'SHOP THE TIMEPIECES',
	landingCollection: 'DISCOVER MORE',
	landingEpitomizingWap: 'Epitomizing artistry, heritage',
	landingEpitomizingWap1: '& exclusivity.',
	landingSpotlightDiscover: 'DISCOVER FALCONS',
	landingHotDiscover: 'DISCOVER FALCONS',
	sell1: 'Sell with',
	sell2: 'Falcons',
	sell3: 'Our professional team will help you get the best value of your items.',
	sell4: 'best value of your items.',
	sell6: 'SELL WITH US',
	sellWap1: 'Our professional team will help you get the best value of your items.',
	sellWap2: '',
	sellWap3: 'Sell with Falcons and our professional team will help',
	sellWap4: 'you get the best value of your items.',
	CarDetail1: 'Our Authentication Guarantee',
	CarDetail2: `Our commitment to authenticity is unwavering. Before any item reaches your hands, it undergoes rigorous scrutiny by our`,
	CarDetail3:
		'team of expert authenticators. They meticulously verify the genuineness of every piece, ensuring that what you receive is',
	CarDetail4: 'nothing short of exceptional.',
	carDeatilWap1: 'Our Authentication',
	carDeatilWap2: 'Guarantee',
	carDeatilWap3:
		'Our commitment to authenticity is unwavering. Before any item',
	carDeatilWap4:
		'reaches your hands, it undergoes rigorous scrutiny by our team',
	carDeatilWap5: 'of expert authenticators. They meticulously verify the',
	carDeatilWap6:
		'genuineness of every piece, ensuring that what you receive is',
	carDeatilWap7: 'nothing short of exceptional.',
	priceOnRequest: 'PRICE ON REQUEST',
	carModel: 'Model',
	carYear: 'Model Year',
	carMileage: 'Mileage',
	carCylinder: 'Cylinder',
	landingAPPAREL:'APPAREL',
	landingKICKS: 'KICKS'
};

export { ar, en };
